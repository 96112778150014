import {
  TFSContract,
  OfferFromService,
  MiscOfferFromService,
  LeaseAndAprOfferFromService,
} from '../../../models/PEMS/PEMOffers';

import {
  createLeaseOfferForView,
  createBuyOfferForView,
  OfferForView,
  mapMiscOffer,
  mapCashOffer,
  isRateOffer,
  OfferType,
} from './offerFactory';
import { cartesianProduct } from '../../../utils/array';
import {
  addToTierTermOffersMap,
  isDefaultLeaseTerms,
  testMilitaryCollege,
  convertTierNumber,
  createTierTermKey,
  testCompatibility,
  isDefaultBuyTerms,
  testCustomerCash,
  selectBaseOffer,
  testContracts,
  getMaxTerm,
} from '../Utils/offersFactoryUtils';
import {
  TierTermsOffersMap,
  TierTermsNonAdMap,
  LeaseOfferTab,
  BuyOfferTab,
  OfferTypes,
  InitObj,
  Tab,
} from '../../../models/VIEW/ViewOffersTab';

import EstimatorStore from '../index';
import { getQueryParams } from '../../../utils/history';
import { Subvention, mapBool2IsSubvented } from '../Utils/subventionUtils';

export const setOffersLeaseTab = ({
  initialOfferId,
  leaseOffers,
  cashOffers,
  miscOffers,
  regionCode,
  modelCode,
  offerType,
  tab,
}: {
  initialOfferId?: string;
  leaseOffers: LeaseAndAprOfferFromService[];
  cashOffers: OfferFromService[];
  miscOffers: MiscOfferFromService[];
  modelCode: string;
  offerType: OfferTypes;
  regionCode: string;
  tab: Tab;
}) => {
  let initOfferObj: InitObj | undefined;
  let tierTermsOffersMap: TierTermsOffersMap = {};
  const tierTermsNonAdMap: TierTermsNonAdMap = {};
  const leaseIdMap: { [idx: number]: number } = {}; // used to keep track which IDs have been processed. Duplicate IDs would mean multiple lease examples

  const availableTerms = Array.from(
    new Set([...[24, 36, 48, 60], ...leaseOffers.map(item => item.highTerm)])
  ).sort((a, b) => a - b); // compare func required by sonarQube

  const nonMatchingModelCodeOffer: LeaseOfferTab['nonMatchingModelCodeOffer'] = {};
  leaseOffers.forEach(item => {
    // calc leaseExampleId
    let leaseExampleId: number | undefined;
    if (leaseIdMap[item.id] === undefined) {
      leaseIdMap[item.id] = 0; // keep track of how many examples there are
    } else {
      ++leaseIdMap[item.id];
      leaseExampleId = leaseIdMap[item.id];
    }

    // create initial offerForView. this will be placed in DS to view...
    const offer = createLeaseOfferForView({
      offer: item,
      modelCode,
      cashOffers,
      miscOffers,
      regionCode,
      leaseExampleId,
    });

    // OATM-1846 disable any lease offers with
    // non matching model codes if lock trim present
    disableNonMatchingLeaseOfferIfTrimLocked(offer, modelCode);

    const key = createTierTermKey(item.tier, item.highTerm);
    if (offer.isAdvertised) {
      // If modelCode is valid with selected model code, check if we need to initially select it based on URL params
      if (offer.modelCode === modelCode) {
        // selects offer based on url params
        initOfferObj =
          createInitialOfferObj({ key, offer, tab, initialOfferId }) ||
          initOfferObj;
        // If modelCode is different than selected model code, use the first non-matching model code offer that comes up.
        // Later offers may come from duplicate offerings
      } else if (
        !nonMatchingModelCodeOffer[key] &&
        !offer.isLowestAdvertisedLease
      ) {
        nonMatchingModelCodeOffer[key] = offer;
      }
      addToTierTermOffersMap({ tierTermsOffersMap, tierTermKey: key, offer });
    } else {
      tierTermsNonAdMap[key] = offer;
    }
  });

  // map cash offers to offersforview
  // cash offers
  initOfferObj = prepareCashOfferForView({
    tab,
    cashOffers,
    initOfferObj,
    initialOfferId,
    tierTermsOffersMap,
  });

  // misc offers
  initOfferObj = prepareMiscOffersForView({
    tab,
    offerType,
    miscOffers,
    initOfferObj,
    tierTermsOffersMap,
    initialOfferId,
  });

  tierTermsOffersMap = processEnhancedAndRefinedOffers(tierTermsOffersMap);

  return {
    initOfferObj,
    availableTerms,
    tierTermsNonAdMap,
    tierTermsOffersMap,
    nonMatchingModelCodeOffer,
  };
};

// add offersForView to tierTermsOffersMap
export const setOffersBuyTab = ({
  initialOfferId,
  cashOffers,
  miscOffers,
  offerType,
  aprOffers,
  tab,
}: {
  initialOfferId?: string;
  cashOffers: OfferFromService[];
  miscOffers: MiscOfferFromService[];
  aprOffers: LeaseAndAprOfferFromService[];
  offerType: OfferTypes;
  tab: Tab;
}) => {
  let initOfferObj: InitObj | undefined;
  let tierTermsOffersMap: TierTermsOffersMap = {};
  const tierTermsNonAdMap: TierTermsNonAdMap = {};

  // create terms
  const maxTerm = getMaxTerm(aprOffers);

  aprOffers.forEach(offer => {
    const offerView = createBuyOfferForView({ offer });
    const tierTermKey = createTierTermKey(offer.tier, offer.highTerm);
    // non advertised offers
    if (offer.isAdvertised) {
      initOfferObj =
        createInitialOfferObj({
          key: tierTermKey,
          offer: offerView,
          tab,
          initialOfferId,
        }) || initOfferObj;
      addToTierTermOffersMap({
        tierTermsOffersMap,
        tierTermKey,
        offer: offerView,
      });
    } else {
      tierTermsNonAdMap[tierTermKey] = offerView;
    }
  });

  // cashOffers
  initOfferObj = prepareCashOfferForView({
    tab,
    cashOffers,
    initOfferObj,
    tierTermsOffersMap,
    initialOfferId,
  });

  // misc offers
  initOfferObj = prepareMiscOffersForView({
    tab,
    offerType,
    miscOffers,
    initOfferObj,
    tierTermsOffersMap,
    initialOfferId,
  });

  tierTermsOffersMap = processEnhancedAndRefinedOffers(tierTermsOffersMap);
  return { initOfferObj, tierTermsOffersMap, tierTermsNonAdMap, maxTerm };
};

type ProcessEnhancedAndRefinedOffersHelpers = {
  groupByType: { [idx: string]: OfferForView[] };
  newArray: OfferForView[];
  type: string;
};
const processEnhancedAndRefinedOffersHelperLease = ({
  groupByType,
  newArray,
  type,
}: ProcessEnhancedAndRefinedOffersHelpers) => {
  // find master lease offer with lower rate ( assuming it is enhanced and refined )
  const latestOffer = groupByType[type]
    .filter(offer => !offer.isLeaseExample)
    .reduce((_latestOffer: OfferForView | undefined, offer) => {
      // Base case
      if (!_latestOffer) {
        return offer;
      }

      // Configured model code test
      if (
        offer.modelCode === offer.selectedModelCode && // Configured model Code
        offer.modelCode !== _latestOffer.modelCode
      ) {
        // Use configured model code if latestOffer is not configured
        return offer;
      }

      // Get one with lower rate
      if (Number(offer.rate) < Number(_latestOffer.rate)) {
        return offer;
      }

      return _latestOffer;
    }, undefined) as OfferForView;

  newArray.push(latestOffer);

  // push all lease examples
  groupByType[type]
    .filter(offer => offer.isLeaseExample)
    .forEach(offer => newArray.push(offer));
};

const processEnhancedAndRefinedOffersHelperAPR = ({
  groupByType,
  newArray,
  type,
}: ProcessEnhancedAndRefinedOffersHelpers) => {
  const latestOffer = groupByType[type].reduce((_latestOffer, offer) => {
    return !_latestOffer
      ? offer
      : Number(offer.rate) < Number(_latestOffer.rate || 0)
      ? offer
      : _latestOffer;
  }, undefined as OfferForView | undefined);
  if (latestOffer) {
    newArray.push(latestOffer);
  }
};

const processEnhancedAndRefinedOffersHelperDefault = ({
  newArray,
  groupByType,
  type,
}: ProcessEnhancedAndRefinedOffersHelpers) => {
  const latestOffer = groupByType[type].reduce((_latestOffer, offer) => {
    return !_latestOffer
      ? offer
      : Number(offer.amount) > Number(_latestOffer.amount || 0)
      ? offer
      : _latestOffer;
  }, undefined as OfferForView | undefined);
  if (latestOffer) {
    newArray.push(latestOffer);
  }
};

// Enhance and refined offers are specific enhancements of a regional offer
// This could mean a better rate/amount for a particular date range. The idea is
// to take the offer with the better rate(lower the better)/amount(higher the better)
// This function can also add offers for other trims to the offers array for a model.
const processEnhancedAndRefinedOffers = (
  tierTermsOffersMap: TierTermsOffersMap
) => {
  for (const prop of Object.keys(tierTermsOffersMap)) {
    const offers = tierTermsOffersMap[prop];
    const groupByType: { [idx: string]: OfferForView[] } = {};
    // group offers by type
    offers.forEach(offer => {
      if (!groupByType[offer.offerType]) {
        groupByType[offer.offerType] = [];
      }
      groupByType[offer.offerType].push(offer);
    });

    // check enhancements for each type
    const newArray: OfferForView[] = []; // this array will be put into tierTermsOfferMap object. yes, it replaces whatever might be created in setOffers function.
    for (const type of Object.keys(groupByType)) {
      // group has more than 1 item. Assume enhance and refined offers
      if (groupByType[type].length > 1) {
        // Lease/APR offer rates go by lower the better
        switch (type) {
          case 'Lease': {
            // find master lease offer with lower rate ( assuming it is enhanced and refined )
            processEnhancedAndRefinedOffersHelperLease({
              groupByType,
              newArray,
              type,
            });
            break;
          }
          case 'APR': {
            processEnhancedAndRefinedOffersHelperAPR({
              groupByType,
              newArray,
              type,
            });
            break;
          }
          // default is cash offers. The higher the amount, the better the offer
          default: {
            processEnhancedAndRefinedOffersHelperDefault({
              groupByType,
              newArray,
              type,
            });
          }
        }
      } else {
        // group only has 1 item
        newArray.push(groupByType[type][0]);
      }
    }

    // finish by assigning new array in tierTermsOffersMap
    tierTermsOffersMap[prop] = newArray;
  }
  return tierTermsOffersMap;
};

// deselects and disables offers that are incomptabile with selected offers
// previously called filterByCompatibility
export const deselectAndDisableIncompatibleOffers = (
  offers: OfferForView[]
) => {
  const lockTrim = EstimatorStore.lockVehicleTrim;
  const modelCode = getQueryParams().trim;
  const selectedOffers = offers.filter(offer => offer.selected);
  const unselectedOffers = offers.filter(offer => {
    // re-enable all unselected offers before rechecking...
    // OATM-1846 except for any non matching modelCodes if lock=trim qs present
    if (
      !lockTrim || // if no trim lock, just enable all unselected offers.
      offer.offerType !== OfferType.LEASE || // if not lease offer no risk of lease jumping, so enable
      (offer.offerType !== OfferType.LEASE && offer.modelCode === modelCode) // if lease offer check model codes match before enabling
    ) {
      offer.disabled = false;
    }
    return !offer.selected;
  });

  selectedOffers.forEach(selectedOffer => {
    unselectedOffers.forEach(unselectedOffer => {
      // The two offers must be compatible with each other
      if (
        (selectedOffer.isRateOffer && unselectedOffer.isRateOffer) ||
        testCustomerCash(selectedOffer, unselectedOffer) ||
        testCompatibility(selectedOffer, unselectedOffer) ||
        testMilitaryCollege(selectedOffer, unselectedOffer) ||
        testContracts(selectedOffer, unselectedOffer)
      ) {
        unselectedOffer.disabled = true;
      }
    });
  });
};

const prepareMiscOffersForView = ({
  tab,
  offerType,
  miscOffers,
  initOfferObj,
  tierTermsOffersMap,
  initialOfferId,
}: {
  offerType: OfferTypes;
  miscOffers: MiscOfferFromService[];
  initOfferObj?: InitObj;
  tierTermsOffersMap: TierTermsOffersMap;
  initialOfferId?: string;
  tab: Tab;
}) => {
  const filteredMiscOffers = miscOffers.filter(
    item =>
      item.isAdvertised &&
      (!item.compatibilityList || item.compatibilityList.includes(offerType))
  );

  // misc offers
  filteredMiscOffers.forEach(item => {
    const mappedMiscOffersForView: OfferForView = mapMiscOffer(item);
    initOfferObj =
      addMiscOffer({
        tierTermsOffersMap,
        offer: mappedMiscOffersForView,
        initialOfferId,
        offerType,
        tab,
      }) || initOfferObj;
  });
  return initOfferObj;
};

// OATM-1486 disables offer if feature enabled and
// offer code doesn't trim
const disableNonMatchingLeaseOfferIfTrimLocked = (
  offer: OfferForView,
  modelCode: string
) => {
  if (EstimatorStore.lockVehicleTrim && offer.modelCode !== modelCode) {
    offer.disabled = true;
  }
};

// add cash offers to tierm terms, and looks for an initialOfferObject
const prepareCashOfferForView = ({
  cashOffers,
  initOfferObj,
  tierTermsOffersMap,
  initialOfferId,
  tab,
}: {
  cashOffers: OfferFromService[];
  initOfferObj?: InitObj;
  tierTermsOffersMap: TierTermsOffersMap;
  initialOfferId?: string;
  tab: Tab;
}) => {
  cashOffers.forEach(item => {
    const offerForView: OfferForView = mapCashOffer(item);
    initOfferObj =
      addCashOffer({
        tierTermsOffersMap,
        offer: offerForView,
        initialOfferId,
        tab,
      }) || initOfferObj;
  });
  return initOfferObj;
};

const createInitialOfferObj = ({
  key,
  offer,
  tab,
  initialOfferId,
  currentTrim,
  trimParam,
}: {
  key: string;
  offer: OfferForView;
  tab: Tab;
  initialOfferId?: string;
  currentTrim?: any;
  trimParam?: string;
}): InitObj | undefined => {
  const isOfferFound = offer.tdaOfferId === initialOfferId;
  const trimsMatch = !trimParam || currentTrim === trimParam;
  offer.selected = isOfferFound && trimsMatch;
  return isOfferFound ? { key, offer, tab } : undefined;
};

// add cash offer to TierTermsOffersMap
const addCashOffer = ({
  tierTermsOffersMap,
  initialOfferId,
  offer,
  tab,
}: {
  tierTermsOffersMap: TierTermsOffersMap;
  initialOfferId?: string;
  offer: OfferForView;
  tab: Tab;
}) => {
  let initOfferObj;
  if (offer.isAdvertised) {
    for (let tier = 0; tier <= 8; tier++) {
      for (let term = 24; term <= 72; term += 12) {
        const key = createTierTermKey(convertTierNumber(tier), term);
        // add in a deep clone lib
        const copiedOffer = Object.assign({}, { ...offer });
        addToTierTermOffersMap({
          tierTermsOffersMap,
          tierTermKey: key,
          offer: copiedOffer,
        });

        const newInitOfferObj = createInitialOfferObj({
          offer: copiedOffer,
          initialOfferId,
          key,
          tab,
        });

        // give priority to tier 1+ and default terms
        initOfferObj =
          (tab === 'lease' && tier === 0 && term === 36 && newInitOfferObj) ||
          (tab === 'buy' && tier === 0 && term === 60 && newInitOfferObj)
            ? newInitOfferObj
            : initOfferObj || newInitOfferObj;
      }
    }
  }
  return initOfferObj;
};

const addMiscOffer = ({
  tierTermsOffersMap,
  initialOfferId,
  offerType,
  offer,
  tab,
}: {
  tierTermsOffersMap: TierTermsOffersMap;
  initialOfferId?: string;
  offerType: OfferTypes;
  offer: OfferForView;
  tab: Tab;
}) => {
  if (!offer.isAdvertised) {
    return undefined;
  }
  let initOfferObj;
  const tiersArray = [...new Array(9)].map((e, idx) => idx); // tslint:disable-line prefer-array-literal
  const termsArray = [...new Array(17)].map((e, idx) => 24 + idx * 3); // tslint:disable-line prefer-array-literal
  const loopArray: Array<[number, number]> = cartesianProduct([
    tiersArray,
    termsArray,
  ]);

  for (const [tier, term] of loopArray) {
    const isSubvented = doesTFSContractConsistsOfTierTerm({
      tfsContracts: offer.tfsContractList as MiscOfferFromService['tfsContractList'],
      offerType,
      tier,
      term,
    });

    const key = createTierTermKey(convertTierNumber(tier), term);
    const newOffer = Object.assign({}, { ...offer });
    newOffer.isSubvented = isSubvented;
    addToTierTermOffersMap({
      tierTermsOffersMap,
      tierTermKey: key,
      offer: newOffer,
    });
    const newInitOfferObj = createInitialOfferObj({
      key,
      tab,
      offer: newOffer,
      initialOfferId,
    });

    // give priority to tier 1+ and default terms
    if (isDefaultLeaseTerms(tab, tier, term, newInitOfferObj)) {
      initOfferObj = newInitOfferObj;
    } else if (isDefaultBuyTerms(tab, tier, term, newInitOfferObj)) {
      initOfferObj = newInitOfferObj;
    } else {
      initOfferObj = initOfferObj || newInitOfferObj;
    }
  }
  return initOfferObj;
};

const doesTFSContractConsistsOfTierTerm = ({
  tfsContracts,
  offerType,
  tier,
  term,
}: {
  tfsContracts: MiscOfferFromService['tfsContractList'];
  offerType: OfferTypes;
  tier: number;
  term: number;
}) => {
  if (tfsContracts && tfsContracts.length) {
    const foundContracts: TFSContract[] = [];
    // This condition means contracts are not required ;/
    if (
      tfsContracts[0].term === undefined ||
      tfsContracts[0].contractType === undefined ||
      tfsContracts[0].tier === undefined
    ) {
      foundContracts.push(tfsContracts[0]);
    }

    tfsContracts.forEach(contract => {
      if (
        contract.contractType &&
        contract.contractType.toLowerCase() === offerType.toLowerCase() &&
        contract.tier === convertTierNumber(tier) &&
        contract.term === term
      ) {
        foundContracts.push(contract);
      }
    });

    if (foundContracts.length === 1) {
      return mapBool2IsSubvented(foundContracts[0].isSubvented);
    } else if (foundContracts.length > 1) {
      return Subvention.BOTH;
    }
  }
  return tfsContracts.length === 0 ? Subvention.BOTH : Subvention.NO;
};

export const preselectOffers = ({
  tab,
  offers,
  offerType,
}: {
  tab: BuyOfferTab | LeaseOfferTab;
  offers: OfferForView[];
  offerType: OfferTypes;
}) => {
  let useCustomRate = false;
  offers.forEach(offer => {
    if (offer.isBase && offer.selected) {
      if (isRateOffer(offer)) {
        useCustomRate = true;
      }
      // true preserves the selection of other offers
      selectOffer({
        tab,
        offer,
        offers,
        offerType,
        preserveOfferSelection: true,
      });
    }
  });
  tab.useCustomRate = useCustomRate;
  return offers;
};

export const selectOffer = ({
  tab,
  offer,
  offers,
  offerType,
  preserveOfferSelection,
}: {
  tab: BuyOfferTab | LeaseOfferTab;
  offer: OfferForView;
  offers: OfferForView[];
  offerType: OfferTypes;
  preserveOfferSelection: boolean;
}) => {
  const rateOffer = offers.find(
    _offer => _offer.isRateOffer && _offer.selected
  );

  // Deselect Rate offer if additional cash/bonus is deselected
  if (
    (rateOffer?.additionalCash?.id === offer.id && !offer.selected) ||
    (rateOffer?.bonusCash?.id === offer.id && !offer.selected)
  ) {
    rateOffer.selected = false;
  }

  if (!offer.isRateOffer) {
    return;
  }

  if (offer.isRateOffer) {
    tab.useCustomRate = false;
    // set lease fields if lease offer
    if (offer.selected) {
      if (offer.offerType === offerType) {
        selectBaseOffer(offer, tab);
      }

      if (!preserveOfferSelection) {
        selectOffersNoPreserveSelection(offers, offer);
      }
    }
  }
};

const selectOffersNoPreserveSelection = (
  offers: OfferForView[],
  offer: OfferForView
) => {
  const deselectAllOtherMainOffers = () => {
    offers.forEach(item => {
      if (
        item.isRateOffer &&
        (item.id !== offer.id || item.leaseExampleId !== offer.leaseExampleId)
      ) {
        item.selected = false;
      }
    });
  };

  const selectAdditionalCashOffers = () => {
    if (offer.additionalCash) {
      offers.forEach(item => {
        if (item.id === offer.additionalCash?.id) {
          item.selected = true;
        }
      });
    }
  };

  const selectBonusCashOffers = () => {
    if (offer.bonusCash) {
      offers.forEach(item => {
        if (item.id === offer.bonusCash?.id) {
          item.selected = true;
        }
      });
    }
  };

  // mark all other main offers deselected
  deselectAllOtherMainOffers();

  // select additionalCash
  selectAdditionalCashOffers();

  // select bonusCash offers
  selectBonusCashOffers();

  // see if the remaining offers are compatibile with the new base offer
  const filteredOffers = offers.filter(
    _offer => !_offer.isRateOffer && _offer.selected
  );
  filteredOffers.forEach(item => {
    const selectedOffers = offers.filter(_offer => _offer.selected);
    selectedOffers.forEach(otherItem => {
      if (
        item.id !== otherItem.id &&
        item.leaseExampleId !== offer.leaseExampleId &&
        !testCustomerCash(item, otherItem) &&
        !testCompatibility(item, otherItem) &&
        !testMilitaryCollege(item, otherItem) &&
        !testContracts(item, otherItem)
      ) {
        item.selected = false;
      }
    });
  });
};
