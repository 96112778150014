import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../../../../../stores';
import { onEnterPress } from 'utils/onEnterPress';

import styles from './Cell.module.scss';

const Cell: React.FC<{ label: string; sup: string }> = ({
  children,
  label,
  sup,
}) => {
  const {
    modalStore: { openDisclaimerModal },
  } = useStore();

  return (
    <div className={styles.root}>
      <div className={styles.labelWrapper}>
        <label className={styles.label} tabIndex={0}>
          {label}
        </label>
        <sup
          className={styles.sup}
          onClick={() => openDisclaimerModal(sup)}
          onKeyPress={e => {
            onEnterPress(e, () => openDisclaimerModal(sup));
          }}
          tabIndex={0}
          role="button"
        >
          {sup}
        </sup>
      </div>
      <div className={styles.inputContainer}>{children}</div>
    </div>
  );
};

export default observer(Cell);
