import React, { useEffect, useState } from 'react';

// unformats value when input is focused, and formats value on blur
export const useAutoFormatValueOnBlur = (
  format: boolean,
  formatter: (idx: number) => string,
  value: string
) => {
  const [isFocused, setIsFocused] = useState(false);
  const [formattedValue, setFormatedValue] = useState(value);
  const onFocus = (event: React.FocusEvent) => setIsFocused(true);
  const onBlur = (event: React.FocusEvent) => setIsFocused(false);

  useEffect(() => {
    if (!format || isFocused) {
      setFormatedValue(value);
    } else {
      setFormatedValue(formatter(Number(value)));
    }
  }, [format, formatter, isFocused, value, formattedValue]);
  return [onFocus, onBlur, formattedValue];
};
