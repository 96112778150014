import React, { useState } from 'react';
import useOutsideAlerter from '../../../../../../hooks/useOutsideAlerter';

import styles from './Dropdown.module.scss';
type Option = {
  label: string;
  value: string | number;
};
type Props = {
  value: string | number;
  options: Option[];
  onClick: (event: any) => void;
};

const Dropdown: React.FC<Props> = ({ options, value, onClick }) => {
  const [isVisible, setVisible] = useState(false);
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => setVisible(false));
  const onDropdownClick = () => setVisible(!isVisible);
  const onMenuItemClick = (event: any) => {
    setVisible(false);
    onClick(event.target.value);
  };
  return (
    <div className={styles.root} ref={wrapperRef}>
      <button
        onClick={onDropdownClick}
        className={styles.button}
        aria-expanded={isVisible}
      >
        <span className={styles.carretContainer}>
          <span className={styles.carret} />
        </span>
        <span className={styles.value}>{value}</span>
      </button>
      {isVisible && (
        <ul className={styles.menu} role="menu">
          {options.map(option => {
            return (
              <li
                key={option.value}
                value={option.value}
                onClick={onMenuItemClick}
                className={styles.menuItem}
                role="menuitem"
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
