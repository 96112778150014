import i18n from 'i18next';
import * as AnalyticsService from './index';
import * as EstimatorBlock from './estimatorAnalytics';
import BrandService from '../../services/BrandService';
import ParentLocationService from '../../services/ParentLocationService';
import { Dealer } from '../../models/VIEW/RequestAQuote';
import { OfferForView } from '../../stores/EstimatorPage/Factories/offerFactory';
import { getQueryParams } from '../../utils/history';
import store from '../../stores/EstimatorPage';
import { GradeVehicleModel } from '../../models/VIEW/EstimatorStore';

export const fireCADStartTag = (
  str: string,
  offers: OfferForView[],
  selectedDealer?: Dealer,
  dealerSearch?: any
) => {
  let block = AnalyticsService.getToolBlock();
  const {
    peBlock,
    vehicleBlock,
  } = EstimatorBlock.buildAnalyticsBlocksForModal();
  block.link_text = str;
  block.content_section = 'contact dealer modal';
  block = { ...block, ...vehicleBlock, ...peBlock };
  const selectedOffers = offers.filter(offer => offer.selected);
  if (selectedOffers.length) {
    block = AnalyticsService.createOffersBlock(
      selectedOffers,
      block,
      BrandService.getBrand()
    );
  }
  if (selectedDealer) {
    block = AnalyticsService.getDealerBlock(
      block,
      selectedDealer,
      BrandService.getBrand()
    );
  }
  if (dealerSearch) {
    block = AnalyticsService.getDealerSearchBlock(
      block,
      BrandService.getBrand(),
      dealerSearch
    );
  }
  AnalyticsService.fireKMCADStart(block);
};

export const fireKMCADCompleteTag = (
  offers: OfferForView[],
  selectedDealer?: Dealer
) => {
  const toolBlock = AnalyticsService.getToolBlock;
  let block = getTagsForContactResponseModal();
  block = { ...block, ...toolBlock };
  const selectedOffers = offers.filter(offer => offer.selected);

  if (selectedOffers.length) {
    block = AnalyticsService.createOffersBlock(
      selectedOffers,
      block,
      BrandService.getBrand()
    );
  }

  if (selectedDealer) {
    block = AnalyticsService.getDealerBlock(
      block,
      selectedDealer,
      BrandService.getBrand()
    );
  }
  block.content_section = 'contact dealer success modal';
  AnalyticsService.fireKMCADComplete(block);
};

export function firePageLoadTag(
  offers: OfferForView[],
  selectedDealer?: Dealer
) {
  const {
    peBlock,
    vehicleBlock,
  } = EstimatorBlock.buildAnalyticsBlocksForModal();
  let block = AnalyticsService.getToolBlock({
    content_section: AnalyticsService.PAGE_TITLE_CONTACT_DEALER_MODAL,
    metrics: 'km-cad-shown', // BAT-5109, this metric being sent with the pageload.
  });
  block = { ...block, ...peBlock, ...vehicleBlock };
  block = extendGlobalBlock(block);
  const selectedOffers = offers.filter(offer => offer.selected);
  if (selectedOffers.length) {
    block = AnalyticsService.createOffersBlock(
      selectedOffers,
      block,
      BrandService.getBrand()
    );
  }
  if (selectedDealer) {
    block = AnalyticsService.getDealerBlock(
      block,
      selectedDealer,
      BrandService.getBrand()
    );
  }
  AnalyticsService.fireAAPageLoad(block);
}

export const onSubmitClick = (offers: OfferForView[]) =>
  fireCtaTags({ linkText: 'submit', offers });

export const onDisclaimerLinkClink = (offers: OfferForView[]) => (
  event: React.SyntheticEvent<HTMLAnchorElement>
) => {
  fireCtaTags({ event, linkText: 'click here', offers });
};

export const onShowMoreDealers = () =>
  fireCtaTags(i18n.t('contact_dealer_modal.load_more'));

type FireCtaTags = {
  event?: React.SyntheticEvent;
  linkText: string;
  selectedDealer?: Dealer;
  offers?: OfferForView[];
};
export const fireCtaTags = ({
  event,
  offers,
  linkText,
  selectedDealer,
}: FireCtaTags) => {
  const {
    peBlock,
    vehicleBlock,
  } = EstimatorBlock.buildAnalyticsBlocksForModal();
  const element = event?.target as HTMLAnchorElement;
  let parentUrl = ParentLocationService.getHostname();
  parentUrl = parentUrl.replace('/payment-estimator/', '');
  const lastIndex = parentUrl.lastIndexOf('/');
  parentUrl = parentUrl.substring(0, lastIndex);
  const getLinkHref = element?.href;
  let block = AnalyticsService.getToolBlock({
    link_action: 'AA-link',
    link_text: linkText,
    link_url: `${parentUrl}${getLinkHref}`,
    link_href: getLinkHref,
  });

  block = extendGlobalBlock(block);
  block = { ...block, ...peBlock, ...vehicleBlock };

  if (selectedDealer) {
    block = AnalyticsService.getDealerBlock(
      block,
      selectedDealer,
      BrandService.getBrand()
    );
  }
  const selectedOffers = offers?.filter(
    (offer: OfferForView) => offer.selected
  );
  if (selectedOffers?.length) {
    block = AnalyticsService.createOffersBlock(
      selectedOffers,
      block,
      BrandService.getBrand()
    );
  }
  AnalyticsService.fireAALink(block);
};

const extendGlobalBlock = (block: any) => {
  const { zip } = getQueryParams();
  block.zipcode = zip;
  block.content_section = AnalyticsService.PAGE_TITLE_CONTACT_DEALER_MODAL;
  return block;
};

export const successModalOnClose = (
  selectedDealer: Dealer,
  offers?: OfferForView[]
) => {
  const analyticsBlock = getTagsForContactResponseModal();
  let block = {
    content_section: AnalyticsService.PAGE_TITLE_CONTACT_DEALER_MODAL_SUCCESS,
    link_action: 'AA-link',
    link_text: 'Continue',
    ...analyticsBlock,
  };
  block.content_section = 'contact dealer success modal';
  if (selectedDealer) {
    block = AnalyticsService.getDealerBlock(
      block,
      selectedDealer,
      BrandService.getBrand()
    );
  }
  AnalyticsService.fireAALink(block);
};

export const getTagsForContactResponseModal = () => {
  const selectedModel = store.selectedYearSeries.tabManager[store.selectedTab]
    .selectedModel as GradeVehicleModel;
  const selectedOffers = selectedModel.peOffers[
    store.selectedTab
  ].offers.filter(offer => offer.selected);

  const {
    peBlock,
    vehicleBlock,
  } = EstimatorBlock.buildAnalyticsBlocksForModal();
  const block = {
    ...AnalyticsService.getToolBlock(),
    ...peBlock,
    ...vehicleBlock,
  };
  if (selectedOffers.length) {
    return AnalyticsService.createOffersBlock(
      selectedOffers,
      block,
      BrandService.getBrand()
    );
  }
  return block;
};
