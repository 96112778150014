import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores';

import { useBreakpoints } from '../../hooks/useBreakpoints';

import DevTools from '../../components/DevTools';
import Heading from './components/Heading';
import Offers from './components/Offers';
import ChangeVehicleLink from './components/ChangeVehicleLink';
import PageContainer from './components/PageContainer';
import PaymentOptions from './components/PaymentOptions';
import BottomSectionDesktop from './components/BottomSectionDesktop';
import BottomSectionTablet from './components/BottomSectionTablet';
import BottomSectionMobile from './components/BottomSectionMobile';
import ChangeVehicleForm from './components/ChangeVehicleForm';
import DisclaimerModal from './components/DisclaimerModal';
import ConfirmModal from './components/ConfirmModal';
import PageLoadAnalytics from './components/PageLoadAnalytics';
import { TopSection, MiddleSection } from './components/Sections';

import * as PostMessageService from '../../services/PostMessageService';
import EstimatorStore from 'stores/EstimatorPage';
import KbbTradeInModal from './components/KbbTradeInModal';

const Estimator: React.FC = () => {
  const { estimatorStore, store } = useStore();
  const { lockVehicleTrim, selectedVehicle, selectedTab, isLoaded } =
    estimatorStore;
  const { setFirstPageLoaded, firstPageLoaded, isFetching } = store;
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  // get data if landing directly on this page
  useEffect(() => {
    if (!firstPageLoaded) {
      estimatorStore.estimatorPageBootstrap();
      setFirstPageLoaded();
    }
    PostMessageService.sendHeight();

    return () => {
      EstimatorStore.isDirty = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching || !isLoaded) {
    return null;
  }

  return (
    <PageLoadAnalytics>
      <PageContainer>
        <DevTools />
        <TopSection>
          {!lockVehicleTrim && <ChangeVehicleLink />}
          <Heading series={selectedVehicle.title} />
          <ChangeVehicleForm />
        </TopSection>
        <MiddleSection>
          <PaymentOptions />
          <Offers selectedTab={selectedTab} />
        </MiddleSection>
        {isDesktop && <BottomSectionDesktop selectedTab={selectedTab} />}
        {isTablet && <BottomSectionTablet selectedTab={selectedTab} />}
        {isMobile && (
          <BottomSectionMobile
            title={selectedVehicle.title}
            payment={268}
            selectedTab={selectedTab}
          />
        )}
        <DisclaimerModal />
        <ConfirmModal />
        <KbbTradeInModal />
      </PageContainer>
    </PageLoadAnalytics>
  );
};

export default observer(Estimator);
