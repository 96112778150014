import { set } from 'mobx';
import {
  LeaseOfferTab,
  BuyOfferTab,
  Disclosure,
  Tab,
} from '../../../../models/VIEW/ViewOffersTab';
import {
  isPaymentsErrorResponse,
  PaymentsErrorResponse,
  PEMCalculateResponse,
  PEMDisclosures,
  isPayment,
  Payments,
  isDapNegative,
} from '../../../../models/PEMS/PEMSCalculate';
import GlobalStore from '../../../GlobalStore';
import {
  isNotConditionalOffer,
  isRateOffer,
  OfferForView,
  OfferType,
} from '../../Factories/offerFactory';
import {
  PEMSCalculateRequestData,
  OfferRequestParam,
  getPayments,
} from '../../../../services/WebServicesAPI';
import { getOffersByTierTermKey } from '../../Utils/offersFactoryUtils';
import { Subvention, mapIsSubvented2Bool } from '../../Utils/subventionUtils';
import { GradeVehicleModel } from '../../../../models/VIEW/EstimatorStore';
import {
  getSelectedOffersForCalculation,
  getNonMatchingModelCodeOffer,
} from './getSelectedOffersForCalculation';

export const calculateCosts = (
  selectedModel: GradeVehicleModel,
  tab: BuyOfferTab | LeaseOfferTab,
  preSelectOffers = true
) => {
  let cashBack = 0;
  let totalMsrp: number | undefined = selectedModel.baseMsrp;
  let grossCapCost: number | undefined = 0;
  let downPaymentCashBack = 0;

  if (preSelectOffers) {
    tab.selectedOffers = getSelectedOffersForCalculation(tab);
  }

  // Loop offers and sum up cash amounts
  tab.selectedOffers.forEach(offer => {
    // amount will be handled in the offers array of pews payload
    if (offer.amount) {
      downPaymentCashBack += Number(offer.amount);
    }
    if (offer.subCash) {
      cashBack += offer.subCash;
    }

    // Lease logic
    if (
      offer.offerType === OfferType.LEASE &&
      offer.modelCode === offer.selectedModelCode &&
      offer.isAdvertised
    ) {
      totalMsrp = offer.totalMsrp;
      grossCapCost = offer.grossCapCost;
    }
  });

  tab.downPaymentSum =
    Number(tab.downPayment) + Number(tab.tradeIn) + downPaymentCashBack;

  tab.grossCapCost = grossCapCost;
  tab.totalMsrp = totalMsrp;
  tab.cashBack = cashBack;
};

export const processPaymentsResult =
  (disclosures: Disclosure[], peOffers: Array<BuyOfferTab | LeaseOfferTab>) =>
  (result: Payments | PaymentsErrorResponse, index: number) => {
    const peOffer = peOffers[index];
    if (isPaymentsErrorResponse(result)) {
      result.errorCode = result.statusCode;
      result.message = JSON.parse(result.body).message;
    } else if (!result.payment && result.type === 'lease') {
      // in case where lease !result.payment && result.type === 'lease', status bar
      // error will be displayed from within selectModel
      // for each specifc model
      (result as unknown as PaymentsErrorResponse).errorCode = true;
      peOffer.isLeaseIneligible = true;
    }
    if (isPaymentsErrorResponse(result)) {
      if (result.message) {
        GlobalStore.showStatusBar(result.message);
      }

      set(peOffer, {
        ...peOffer,
        payment: null,
        markup: null,
        targetPayment: null,
        rate: null,
        dueAtSigning: null,
        disclosures,
      });
    } else if (isDapNegative(result)) {
      GlobalStore.showStatusBar('Invalid input');

      set(peOffer, {
        ...peOffer,
        payment: null,
        markup: null,
        targetPayment: null,
        rate: null,
        dueAtSigning: null,
        disclosures,
      });
    } else {
      GlobalStore.closeStatusBar();

      set(peOffer, {
        payment: Math.round(result.payment),
        markup: result.markup,
        targetPayment: Math.round(result.payment),
        rate: result.rateValue,
        dueAtSigning: result.dueAtSigning
          ? Math.round(result.dueAtSigning)
          : NaN,
        disclosures,
        securityDeposit: result.securityDeposit,
        securityDepositWaiver:
          result.type === 'lease' && result.securityDeposit === 0,
        tier: result.tier === '1+' ? 0 : Number(result.tier),
        terms: String(result.term),
      });
    }
  };

export const createOffersForPEMSCalculateAPICall = (offers: OfferForView[]) => {
  return offers.reduce((map, offer) => {
    const alteredOffer = { ...offer };
    // sum amounts for misc into a single rebate
    // other offers gets pushed into their own offer type
    if (offer.isMisc) {
      // find rebate in current mapping
      const filtered = map.filter(off => off.offerType === OfferType.REBATE);
      // sum amount if found
      // add offer to mapping if not found
      if (filtered.length) {
        filtered[0].offerAmount =
          Number(filtered[0].offerAmount) + Number(offer.amount);
        filtered[0].offerAmountDown = filtered[0].offerAmount;
      } else if (alteredOffer.isSubvented === Subvention.BOTH) {
        // WFMB-198 if this misc offer works with both subvented and
        // non-subvented rate offers, switch it's subvention
        // to match that of rate offer
        const rateOffer = offers.find(_offer => isRateOffer(_offer));
        if (rateOffer) {
          alteredOffer.isSubvented = rateOffer.isSubvented;
        }
        map.push(createOfferParam(alteredOffer));
      } else {
        map.push(createOfferParam(alteredOffer));
      }
    } else {
      map.push(createOfferParam(alteredOffer));
    }
    return map;
  }, [] as OfferRequestParam[]);
};

// for calculate api call
export const createOfferParam = (offer: OfferForView) => {
  const offerRequest: Partial<OfferRequestParam> = {
    advertised: offer.isAdvertised,
    compatibility: offer.compatibility,
    isBase: offer.isBase,
    offerType: offer.offerType,
    offerId: offer.tdaOfferId,
    isSubvented: mapIsSubvented2Bool(offer.isSubvented),
    isRateOffer: offer.isRateOffer,
  };
  switch (offer.offerType) {
    case OfferType.APR:
    case OfferType.LEASE:
      offerRequest.type = 'RATE_INCENTIVE';
      offerRequest.rate = offer.rate;
      offerRequest.term = offer.terms;
      offerRequest.highTerm = offer.terms;
      offerRequest.tier = offer.tier;
      offerRequest.additionalCashId = offer.additionalCash?.id;
      if (offer.offerType === OfferType.LEASE) {
        offerRequest.acquisitionFeeCapitalized =
          offer.acquisitionFeeCapitalized;
        offerRequest.downPayment = offer.downPayment;
        offerRequest.mileage = offer.mileage;
        offerRequest.dueAtSigning = offer.dueAtSigning;
        offerRequest.targetPayment = offer.payment;
        offerRequest.dealerGross = offer.dealerGross;
      } else {
        offerRequest.subCash = offer.subCash;
      }
      break;
    case OfferType.CUSTOMER_CASH:
      offerRequest.type = 'CUSTOMER_CASH';
      offerRequest.amount = offer.amount;
      offerRequest.amountDown = offer.amount;
      break;
    default:
      offerRequest.type = 'REBATE';
      offerRequest.amount = offer.amount;
      offerRequest.amountDown = offer.amount;
      offerRequest.name = offer.offerType;
      if (!offer.offerType) {
        offerRequest.additionalCashId = offer.id;
      }
      if (offer.offerType === OfferType.COMPLIMENTARY_FIRST_PAYMENT) {
        offerRequest.maxAmountPerMonth = offer.maxAmountPerMonth;
      }
  }
  if (offer.modelCode) {
    offerRequest.modelCode = offer.modelCode;
    offerRequest.grossCapCost = offer.grossCapCost;
    offerRequest.subCash = offer.subCash;
    offerRequest.advertisedVinMsrp = offer.totalMsrp;
    offerRequest.advertisedTrim = offer.modelCode;
  }
  return offerRequest as OfferRequestParam;
};

export const mapDisclosures = (data: PEMDisclosures) => {
  const disclosures: Disclosure[] = [];
  if (data) {
    for (const [key, value] of Object.entries(data)) {
      disclosures.push({
        disclosureType: key,
        disclosureValue: value,
      });
    }
  }
  return disclosures;
};

// will set disclosures and selected offers for each model buy/lease
// on both sides...
export const getPaymentsFromPems = async ({
  data,
  regionCode,
  peOffers,
}: {
  peOffers: Array<BuyOfferTab | LeaseOfferTab>;
  regionCode: string;
  data: PEMSCalculateRequestData[];
}): Promise<PEMCalculateResponse> => {
  const { data: responseData } = await getPayments({ regionCode, data });
  const disclosures: Disclosure[] = mapDisclosures(responseData.disclosures);

  // if running in regular mode (just for 1 single APR or LEASE), below will make no changes
  const [filteredPayments, filteredPeOffers] = filterHigherPaymentAprResponses(
    responseData.payments,
    peOffers
  );

  // otherwise future code will just grab first APR payment instead of lowest...
  responseData.payments = filteredPayments;

  const processHandler = processPaymentsResult(disclosures, filteredPeOffers);
  filteredPayments.forEach(processHandler);
  return responseData;
};

// for lease payments, we have to select one with lowest payment
// between 36 and 39 months.
// for APR payments, we have to select one with lowest payment
// between 60 month and 72 months.
const filterHigherPaymentAprResponses = (
  payments: Array<Payments | PaymentsErrorResponse>,
  peOffers: Array<BuyOfferTab | LeaseOfferTab>
) => {
  const _payments = [...payments];
  const _peOffers = [...peOffers];
  let i = _payments.length - 1;
  while (i >= 0) {
    const payment = _payments[i];
    if (isPayment(payment)) {
      const otherAPRPayment = payments[i - 1];
      if (
        otherAPRPayment &&
        isPayment(otherAPRPayment) &&
        otherAPRPayment.type === payment.type
      ) {
        const highestPaymentIdx =
          payment.payment > otherAPRPayment.payment ? i : i - 1;
        _payments.splice(highestPaymentIdx, 1);
        _peOffers.splice(highestPaymentIdx, 1);
      }
    }
    i--;
  }
  return [_payments, _peOffers] as const;
};

export const buildRequest = (
  {
    year,
    terms,
    series,
    peOffer,
    baseMsrp,
    modelCode,
    customMsrp,
    pricingArea,
    isLowestPaymentCalculation,
    sendDownPayment = false,
    state,
  }: {
    year: string;
    terms: string;
    series: string;
    peOffer: BuyOfferTab | LeaseOfferTab;
    baseMsrp: number;
    modelCode: string;
    customMsrp?: string;
    pricingArea: string;
    isLowestPaymentCalculation?: boolean;
    sendDownPayment?: boolean;
    state: string;
  },
  code?: string
) => {
  const type = peOffer.__tab__;
  const tier = peOffer.tier === 0 ? '1+' : String(peOffer.tier);
  const tierTermKey = `${tier} ${terms}`;
  const offersToSend = !isLowestPaymentCalculation
    ? [...peOffer.selectedOffers]
    : getOffersToSend(peOffer, tierTermKey);

  const payloadOffers = createOffersForPEMSCalculateAPICall(offersToSend);
  const request: Partial<PEMSCalculateRequestData> = {
    type,
    msrp: Number(customMsrp),
    tier,
    term: terms, // don't take from peOffer as that may just be default value
    model: series,
    offers: payloadOffers,
    tradeIn: Number(peOffer.tradeIn),
    mileage: isLowestPaymentCalculation ? null : peOffer.mileage,
    baseMsrp, // baseMsrp + dest fee
    trimCode: modelCode,
    modelYear: year,
    pricingArea,
    downPayment:
      isLowestPaymentCalculation && !sendDownPayment
        ? null
        : peOffer.downPayment,
    state,
  };

  return request as PEMSCalculateRequestData;
};

/**
 * Gets all applicable offers from which calclulations api will find
 * valid offer combo with lowest payment.
 */
const getOffersToSend = (
  peOffer: BuyOfferTab | LeaseOfferTab,
  tierTermKey: string
) => {
  const type = peOffer.__tab__;
  const offersToSend = [
    ...getOffersByTierTermKey(peOffer.tierTermsOffersMap, tierTermKey),
  ]
    .filter(isNotConditionalOffer)
    .filter(
      offer =>
        !offer.isRateOffer ||
        offer.offerType === OfferType.APR ||
        offer.isCurrentModelCode // non rate offers allowed, otherwise only allow APR rate offers or Lease rate offers with matching model codes
    );

  const rateOfferExists = offersContainRateOffer(offersToSend, type);
  const nonMatchingModelCodeOffer = getNonMatchingModelCodeOffer(
    peOffer,
    tierTermKey,
    []
  );

  // if no rate offer, try non matching model code offers
  if (!rateOfferExists && nonMatchingModelCodeOffer) {
    offersToSend.push(nonMatchingModelCodeOffer);

    // if still no rate offers, try non advertised rate offer
  } else if (!rateOfferExists && peOffer.tierTermsNonAdMap[tierTermKey]) {
    // if no lease offer in offersToSend, add non advertised offers
    offersToSend.push(peOffer.tierTermsNonAdMap[tierTermKey]);
  }

  return offersToSend;
};

const offersContainRateOffer = (offers: OfferForView[], type: Tab) =>
  !!offers.find(
    offer =>
      offer.offerType === (type === 'buy' ? OfferType.APR : OfferType.LEASE)
  );
