import i18n from 'i18next';
import ZipCodeStore from '../ZipCodeStore';
import EstimatorStore from './index';
import { getQueryParams } from '../../utils/history';

import * as estimatorAnalyticsService from '../../utils/Analytics/estimatorAnalytics';
import * as PostMessageService from '../../services/PostMessageService';
import * as AnalyticsService from '../../utils/Analytics';
import { OfferForView, OfferType } from './Factories/offerFactory';
import {
  GradeVehicleModel,
  Grades,
  GradeView,
} from '../../models/VIEW/EstimatorStore';
import ModalStore from '../ModalStore';
import {
  getCreditScoreItemById,
  CreditScore,
} from '../../constants/CreditScoreConstants';
import { filterStringsFromNumberInputs } from '../../utils/numbers';
import GlobalStore from '../GlobalStore';
import { Tab } from '../../models/VIEW/ViewOffersTab';
import { getGradeGivenCode } from './ServiceWrappers/gradesAndSeries';
import { getCreditScoreItem } from './Utils/offersFactoryUtils';

let timeout: any;

const estimatorPageHandlers = {
  changeVehicleOnClick: () => {
    const { zip } = getQueryParams();
    ZipCodeStore.navigateToVehicleSelectPage();

    // FIRE ANALYTICS BAT-4744
    let block = estimatorAnalyticsService.extendWithVehicleBlock({
      link_action: 'AA-link',
    });
    block = estimatorAnalyticsService.extendWithPEBlock(block);
    block = estimatorAnalyticsService.extendWithGlobalBlock(block);
    block = AnalyticsService.getToolBlock(block);
    block['link_text'] = 'change vehicle'; // BAT-4585
    AnalyticsService.fireAALink(block);

    const postMessageParams = {
      module: 'vehicleSelect',
      zip,
    };
    PostMessageService.stateChange(JSON.stringify(postMessageParams));

    GlobalStore.closeStatusBar();
  },
  onSelectedYearSeriesChange: (yearSeriesIdx: number) => {
    const selectedYearSeries = EstimatorStore.yearSeries[yearSeriesIdx];
    EstimatorStore.selectYearSeries(selectedYearSeries, true);
    GlobalStore.closeStatusBar();
  },
  onSelectedGradeChange: async (gradeCode: string) => {
    const grades = EstimatorStore.yearSeries[
      EstimatorStore.selectedVehicle.yearSeries
    ]?.grades as Grades;

    // have grade, need to get new model list and select first one...
    const grade = getGradeGivenCode({
      grades,
      gradeCode,
    }) as GradeView;
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedModel as GradeVehicleModel;

    GlobalStore.closeStatusBar();

    if (selectedModel.isCustomMsrp) {
      ModalStore.displayConfirmGradeChangeModal = true;
      ModalStore.confirmGradeChangeForGrade = grade;
    } else {
      EstimatorStore.isModelCodeJumped = true;
      await EstimatorStore.selectGrade({
        grade,
        byuser: true,
        _caller: 'grade',
      });
    }
  },
  onSelectedModelChange: (modelCode: string) => {
    GlobalStore.closeStatusBar();
    const selectedGrade = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedGrade as GradeView;
    const findModelGivenCode = (_modelCode: string) =>
      selectedGrade.models.find(
        _model => _model.code === _modelCode
      ) as GradeVehicleModel;
    const model = findModelGivenCode(modelCode);

    // get model given string
    EstimatorStore.selectModel(model, true);
    EstimatorStore.updateValues(EstimatorStore.selectedTab);
    EstimatorStore.selectedVehicle.model = modelCode;
  },
  toggleSelectedTab: async (tab: Tab) => {
    if (EstimatorStore.selectedTab === tab) {
      // do nothing, return early
      return;
    }
    GlobalStore.isFetching = true;

    const oldSelectedModelDetails = (
      EstimatorStore.selectedYearSeries.tabManager[EstimatorStore.selectedTab]
        .selectedModel as GradeVehicleModel
    ).peOffers[EstimatorStore.selectedTab];
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[tab]
      .selectedModel as GradeVehicleModel;

    // Persist Downpayment/TradeIn fields across tabs
    if (ZipCodeStore.regionCode === '500') {
      // SET region mappings are different for Finance and Lease, after toggling, reset to 1+ tier
      selectedModel.peOffers[tab].creditScore = getCreditScoreItem(
        '1+',
        tab
      ) as CreditScore;
    } else {
      selectedModel.peOffers[tab].creditScore =
        oldSelectedModelDetails.creditScore;
    }

    selectedModel.peOffers[tab].tier =
      oldSelectedModelDetails.creditScore?.id || 0;
    selectedModel.peOffers[tab].downPayment =
      oldSelectedModelDetails.downPayment;
    selectedModel.peOffers[tab].tradeIn = oldSelectedModelDetails.tradeIn;

    EstimatorStore.selectedOffersOnTierTermChange(tab);
    EstimatorStore.updateOffersOnFormChange(tab);
    EstimatorStore.updateState();

    try {
      await EstimatorStore.updateValues(tab);
      EstimatorStore.selectedTab = tab;
      estimatorAnalyticsService.fireSelectedTagChange(tab);
    } catch (error) {
      // errors caught lower down
    }
    GlobalStore.isFetching = false;
  },
  onCreditChange: (creditItemId: number) => {
    const selectedModelDetails = (
      EstimatorStore.selectedYearSeries.tabManager[EstimatorStore.selectedTab]
        .selectedModel as GradeVehicleModel
    ).peOffers[EstimatorStore.selectedTab];
    if (selectedModelDetails.creditScore?.id !== creditItemId) {
      const creditScoreItem = getCreditScoreItemById(
        creditItemId,
        ZipCodeStore.regionCode,
        EstimatorStore.selectedTab
      );
      selectedModelDetails.creditScore = creditScoreItem as CreditScore;
      selectedModelDetails.tier = creditScoreItem?.id || 0;

      selectedModelDetails.useCustomRate = false;
      EstimatorStore.selectedOffersOnTierTermChange(EstimatorStore.selectedTab);
      EstimatorStore.updateOffersOnFormChange(EstimatorStore.selectedTab);
      EstimatorStore.onFormChange({
        title: 'Estimated Credit Score',
        clickType: 'Dropdown',
        sendAnalytic: !EstimatorStore.creditChangeSent,
      });
      EstimatorStore.creditChangeSent = true;
    }
  },
  onTermsChange: (terms: number) => {
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedModel as GradeVehicleModel;
    const details = selectedModel.peOffers[EstimatorStore.selectedTab];
    if (terms !== Number(details.terms) && selectedModel) {
      details.terms = String(terms);
      EstimatorStore.selectedOffersOnTierTermChange(EstimatorStore.selectedTab);
      EstimatorStore.updateOffersOnFormChange(EstimatorStore.selectedTab);
      EstimatorStore.onFormChange({
        title: 'Term Length',
        clickType: 'Dropdown',
        sendAnalytic: !EstimatorStore.termChangeSent,
      });
      EstimatorStore.termChangeSent = true;
    }
  },
  onMileageChange: (mileageItem: number) => {
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedModel as GradeVehicleModel;
    const details = selectedModel.peOffers[EstimatorStore.selectedTab];
    if (details.mileage !== mileageItem && selectedModel) {
      // WFMB-304
      const offers = selectedModel.peOffers[EstimatorStore.selectedTab].offers;
      const offerToSelect = offers.find(
        offer =>
          offer.offerType === OfferType.LEASE &&
          offer.modelCode === selectedModel.code &&
          offer.mileage === mileageItem
      );

      if (offerToSelect) {
        EstimatorStore.offerSelect(offerToSelect);
      }

      details.mileage = mileageItem;
      selectedModel.peOffers[EstimatorStore.selectedTab].mileage = mileageItem;
      EstimatorStore.onFormChange({
        title: 'Annual Mileage',
        clickType: 'Dropdown',
        sendAnalytic: !EstimatorStore.mileageChangeSent,
        isUpdateRequired: !offerToSelect,
      });
      EstimatorStore.mileageChangeSent = true;
    }
  },
  onDownpaymentChange: (event: React.ChangeEvent<HTMLInputElement>) => {
    EstimatorStore.isDirty = true;
    const newDownPayment = event.target.value;
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedModel as GradeVehicleModel;
    const details = selectedModel.peOffers[EstimatorStore.selectedTab];
    if (selectedModel) {
      selectedModel.peOffers[EstimatorStore.selectedTab].downPayment =
        Number(newDownPayment);
    }
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      details.downPayment = Number(
        filterStringsFromNumberInputs(newDownPayment)
      );
      EstimatorStore.onFormChange({
        title: 'Down Payment',
        clickType: 'Free Text',
        sendAnalytic: !EstimatorStore.downPaymentChangeSent,
      });
      EstimatorStore.downPaymentChangeSent = true;
    }, 500);
  },
  onTradeInChange: (event: React.ChangeEvent<HTMLInputElement> | number) => {
    EstimatorStore.isDirty = true;

    let newTradeIn = 0;

    if (typeof event === 'number') {
      newTradeIn = event;
    } else {
      newTradeIn = Number(
        filterStringsFromNumberInputs(
          (event as React.ChangeEvent<HTMLInputElement>).target.value
        )
      );
    }
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedModel as GradeVehicleModel;
    if (selectedModel) {
      selectedModel.peOffers[EstimatorStore.selectedTab].tradeIn = newTradeIn;
    }
    EstimatorStore.onFormChange({
      title: 'Trade In Amount',
      clickType: 'Free Text',
      sendAnalytic: !EstimatorStore.tradeInChangeSent,
    });
    EstimatorStore.tradeInChangeSent = true;
  },
  openKbb: () => {
    const useLang = i18n.language;
    const url =
      useLang === 'es'
        ? 'https://www.toyota.com/espanol/kbb'
        : 'https://www.toyota.com/kbb'; //tslint:disable-line
    const windowProps =
      'toolbar=no,scrollbars=auto,resizable=yes,top=100,left=100,width=500,height=700';

    window.open(url, '', windowProps);
    estimatorAnalyticsService.fireVehSelTag({
      link_href: url,
      link_url: url,
      link_text: i18n.t('trade_in_cta'),
    });
  },
  offerClick: (offer: OfferForView) => {
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedModel as GradeVehicleModel;
    if (selectedModel.isCustomMsrp) {
      if (offer.offerType === OfferType.LEASE && !offer.selected) {
        ModalStore.displayConfirmOfferChangeModal = true;
        ModalStore.confirmOfferChangeForOffer = offer; // used by confirmOfferChange
      } else {
        EstimatorStore.offerSelect(offer);
      }
    } else {
      estimatorPageHandlers.selectCorrespondingLeaseOrTfsSubCashOffer(
        offer,
        selectedModel
      );
      EstimatorStore.offerSelect(offer);
    }
  },

  selectCorrespondingLeaseOrTfsSubCashOffer: (
    offer: OfferForView,
    selectedModel: GradeVehicleModel
  ) => {
    // When there is an advertised sub cash available along with an advertised lease, if one is selected, both should be selected and vice versa
    selectedModel.peOffers[EstimatorStore.selectedTab].offers.forEach(
      tempOffer => {
        if (
          offer.offerType === OfferType.TFS_LEASE_SUB_CASH &&
          tempOffer.offerType === OfferType.LEASE &&
          tempOffer.modelCode === selectedModel.code
        ) {
          EstimatorStore.offerSelect(tempOffer);
        }

        if (
          offer.offerType === OfferType.LEASE &&
          tempOffer.offerType === OfferType.TFS_LEASE_SUB_CASH &&
          tempOffer.amount === offer.subCash
        ) {
          tempOffer.selected = !offer.selected;
        }
      }
    );
  },

  confirmOfferChange: async () => {
    const offer = ModalStore.confirmOfferChangeForOffer as OfferForView;
    ModalStore.confirmOfferChangeForOffer = null;
    offer.selected = false;
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedModel as GradeVehicleModel;
    selectedModel.isCustomMsrp = false;
    selectedModel.baseMsrp = selectedModel.efcMsrp as number;
    await EstimatorStore.updateValues('buy');
    await EstimatorStore.updateValues('lease');
    EstimatorStore.offerSelect(offer);
    ModalStore.displayConfirmOfferChangeModal = false;
  },

  confirmGradeChange: async () => {
    EstimatorStore.isModelCodeJumped = true;
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedModel as GradeVehicleModel;
    selectedModel.isCustomMsrp = false;
    selectedModel.baseMsrp = selectedModel.efcMsrp as number;
    await EstimatorStore.updateValues('lease');
    await EstimatorStore.updateValues('buy');
    const grade = ModalStore.confirmGradeChangeForGrade as GradeView;
    await EstimatorStore.selectGrade({ grade, byuser: true, _caller: 'grade' });
    ModalStore.displayConfirmGradeChangeModal = false;
    ModalStore.confirmGradeChangeForGrade = null;
  },
};

export default estimatorPageHandlers;
