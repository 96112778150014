import EstimatorStore from '..';
import {
  EFCModelMap,
  ModelMap,
  VehicleCatMap,
} from '../../../constants/VehicleConstants';
import {
  EFCTrim,
  EFCTrimAttribute,
  EFCTrimForView,
} from '../../../models/EFC/EFCTrimsV2';
import { MYCOModels } from '../../../models/MYCOModels';
import { GradeVehicleModel, Grades } from '../../../models/VIEW/EstimatorStore';
import BrandService from '../../../services/BrandService';
import { ColorData, getColors } from '../../../services/ColorsService';
import { getSeries } from '../../../services/SeriesService';
import { TrimData, getTrims } from '../../../services/TrimsService';
import { getMycoFile } from '../../../services/WebServicesAPI';
import { getQueryParams } from '../../../utils/history';
import { getMSRPPriceFromTrim, sortModelsByMSRP } from '../../../utils/vehicle';
import ZipCodeStore from '../../ZipCodeStore';
import {
  defaultBuyOffer,
  defaultLeaseOffer,
} from '../Utils/offersFactoryUtils';

export const getGradeGivenCode = ({
  grades,
  gradeCode,
}: {
  grades: Grades;
  gradeCode: string;
}) => grades.find(grade => grade.code === gradeCode);

export const getSeriesAndGrades = async ({
  region,
  trim,
}: {
  region: string;
  trim?: string;
}) => {
  const filteredSeries = await getSeries(region);
  let grades = await getAndProcessVehicleData(region);
  grades = filterGradesIfTrimLocked(grades, trim);
  return { filteredSeries, grades };
};

// BAT-1486 filter out grades not relevant to this locked down trim
const filterGradesIfTrimLocked = (grades: Grades, trim?: string) => {
  if (EstimatorStore.lockVehicleTrim) {
    grades = grades.filter(
      item => item.models.filter(model => model.code === trim).length
    );
  }
  return grades;
};

export const getAndProcessVehicleData = async (region: string) => {
  // make getVehicleData call given params in query string...
  const params = getQueryParams();
  const { year, series, msrp, trim } = params;
  if (!series || !year) {
    throw new Error('Missing series or year query string parameter');
  }
  const agumentedModels = await callAndProcessVehicleEndpoint({
    region,
    year: year as string,
    series: series as string,
  });

  // create grades structure
  return createGrades({
    brand: BrandService.getBrand(),
    series: series as string,
    models: agumentedModels,
    trimParam: trim as string,
    msrp: msrp as string,
  });
};

const setGradeDetails = (model: EFCTrimForView) => {
  const grade = getTrimAttribute(model.attributes, 'grade');
  return [grade?.code, grade?.title] as [string, string];
};

const createGrades = ({
  msrp,
  brand,
  series,
  models,
  trimParam,
}: {
  msrp?: string;
  trimParam?: string;
  brand: string;
  series: string;
  models: EFCTrimForView[];
}) => {
  const grades: Grades = [];
  // sort models by msrp
  const sortedModels = sortModelsByMSRP<EFCTrimForView>(models);
  sortedModels.forEach(model => {
    const newModel = newModelFactory(brand, series, model);

    if (msrp && trimParam && trimParam === model.code) {
      newModel.efcMsrp = newModel.baseMsrp;
      newModel.customMsrp = msrp;
      newModel.baseMsrp = Number(msrp);
      newModel.isCustomMsrp = true;
    }

    const [gradeCode, gradeTitle] = setGradeDetails(model);

    // Get gradeIndex of current grade code
    let gradeIndex = -1;
    for (let i = 0; i < grades.length; i++) {
      if (grades[i].code === gradeCode) {
        gradeIndex = i;
        break;
      }
    }

    // If gradeIndex isn't found, create new grade item in the yearSeries.Obj.grades
    if (gradeIndex === -1) {
      const grade = {
        code: gradeCode,
        title: gradeTitle,
        models: [],
        lastSelectedModel: undefined,
      };
      gradeIndex = grades.push(grade) - 1;
    }

    // Only add model if the model code doesn't already exists in the grades.models array
    if (
      !grades[gradeIndex].models.filter(_model => _model.code === newModel.code)
        .length
    ) {
      grades[gradeIndex].models.push(newModel);
    }
  });
  return grades;
};

const newModelFactory = (
  brand: string,
  series: string,
  model: EFCTrimForView
): GradeVehicleModel => {
  const transmission = getTrimAttribute(model.attributes, 'transmission');
  return {
    baseMsrp: getMSRPPriceFromTrim(model),
    category: VehicleCatMap[brand === 'TOY' ? 'toyota' : 'lexus'][series], // this category is unused, and series can now belong to multiple cats
    code: model.code,
    engine: model.shortTitle,
    transmission: transmission?.title,
    trimmedTitle: model.title ? model.title.replace(/\[[^[\]]*]/g, '') : '',
    title: model.title,
    peOffers: {
      buy: defaultBuyOffer(),
      lease: defaultLeaseOffer(),
    },
    carJellyImage: model.carJellyImage,
  };
};

// updates baseMSRP, carJellyImage and carJellyFromBat on seriesForView
export const processGetVehicleDataResponse = async ({
  year,
  series,
  vehicleData,
}: {
  year: string;
  series: string;
  vehicleData: TrimData;
}): Promise<EFCTrimForView[]> => {
  const region = ZipCodeStore.regionCode;

  // we should only be making call for single year/series for EstimatorPage
  const seriesCode = series;
  const seriesItem = EFCModelMap[seriesCode];

  // call colours api, and make sure we create a map of all
  const colorResults = await getColors({
    years: year,
    region,
  });
  const trims = Object.values(vehicleData[year][series]);
  return updateModelsWithShortTitlesAndJellies({
    year,
    series,
    seriesItem,
    models: trims,
    colorResults,
  });
};

const getTrimAttribute = (
  attributes: EFCTrimAttribute[],
  type: EFCTrimAttribute['type']
) => attributes.find(ele => ele.type === type);

const updateModelsWithShortTitlesAndJellies = ({
  year,
  series,
  models,
  seriesItem,
  colorResults,
}: {
  year: string;
  series: string;
  models: EFCTrim[];
  seriesItem: ModelMap;
  colorResults: ColorData;
}) => {
  return models.map(trim => {
    // build short title string
    const engine = getTrimAttribute(trim.attributes, 'engine');
    let shortTitle = '';
    if (engine && engine.title) {
      shortTitle = createShortTitleFromEngine(engine.title);
    }

    let carJellyImage = `${process.env.REACT_APP_EFC_BASE_CAR_JELLY_URL}`;
    const colorDataForTrim = colorResults?.[year]?.[series]?.[trim?.code];
    if (
      colorDataForTrim &&
      seriesItem.vehicleColor &&
      colorDataForTrim[seriesItem.vehicleColor]
    ) {
      carJellyImage +=
        colorDataForTrim[seriesItem.vehicleColor].car_jelly_image;
    } else {
      // default back to first exterior color in map for trim
      if (Object.values(colorDataForTrim ?? {})[0]) {
        carJellyImage += Object.values(colorDataForTrim)[0].car_jelly_image;
      } else {
        carJellyImage = '/images/blank_car_PE.png';
      }
    }

    return Object.assign({}, trim, {
      carJellyImage,
      shortTitle,
    }) as EFCTrimForView;
  });
};

const createShortTitleFromEngine = (engineTitle: string) => {
  const splitEngine = engineTitle.split(' ');
  return splitEngine.length > 1
    ? `${splitEngine[0]} ${splitEngine[1]}`
    : splitEngine[0];
};

export type CallAndProcessVehicleEndpoint = {
  region: string;
  year: string;
  series: string; // e.g 'highlander'
};
export const callAndProcessVehicleEndpoint = async ({
  region,
  year,
  series,
}: CallAndProcessVehicleEndpoint) => {
  const response: TrimData = await getTrims({
    years: year,
    region,
    series,
  });
  const mycoModels: MYCOModels = await getMycoFile();

  const validYearModels: Set<string> = new Set<string>();
  mycoModels.yearSeriesModelsNameList.forEach(yearSeries => {
    yearSeries.models.forEach(model => {
      validYearModels.add(`${yearSeries.year}${model.id}`);
    });
  });

  Object.keys(response).forEach(responseYear => {
    Object.keys(response[responseYear]).forEach(responseSeries => {
      Object.keys(response[responseYear][responseSeries]).forEach(trim => {
        if (!validYearModels.has(`${responseYear}${trim}`)) {
          delete response[responseYear][responseSeries][trim];
        }
      });
    });
  });

  return await processGetVehicleDataResponse({
    year,
    series,
    vehicleData: response,
  });
};
