import React from 'react';
import { useStore } from '../../../../../../stores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  formatNumber,
  formatNumberWithCurrency,
} from '../../../../../../utils/numbers';

import styles from './PaymentDetails.module.scss';

import Disclaimer from '../../../Disclaimer';
import Cell from '../../../Cells';
import {
  createDownpaymentDisclaimer,
  createDueAtSigningDisclaimer,
} from '../../../../../../modules/Estimator/utils';

const PaymentDetailsDesktop: React.FC = () => {
  const { t } = useTranslation();
  const {
    estimatorStore: { payOptForm, selectedTab },
    zipCodeStore: { regionCode, vehicleSelectPageImageOnload },
  } = useStore();
  const { carJellyImage, title } = payOptForm;

  const isBuyTab = selectedTab === 'buy';
  const secondPaymentCell = isBuyTab ? t('estimated_apr') : t('annual_mileage');
  const thirdPaymentCell = isBuyTab
    ? t('down_payment')
    : t('amount_due_at_signing');
  const fourthPaymentCell = isBuyTab ? t('buy_for') : t('lease_for');
  const {
    dueAtSigning,
    payment,
    mileage,
    terms,
    rate,
    offers,
    selectedOffers,
    securityDepositWaiver,
    downPaymentSum,
    markup,
  } = payOptForm;

  const thirdPaymentCellDisclaimer = isBuyTab
    ? createDownpaymentDisclaimer(offers)
    : createDueAtSigningDisclaimer(
        securityDepositWaiver,
        selectedOffers,
        regionCode
      );

  const formatedPayment = typeof payment === 'number' ? String(payment) : '-'; // if undefined render "-" for payment
  const formatedThirdPaymentValue = isBuyTab
    ? formatNumberWithCurrency(downPaymentSum)
    : formatNumberWithCurrency(dueAtSigning);

  return (
    <div className={styles.payment}>
      {/* Car jelly image */}
      <div className={styles.col1}>
        <div className={styles.carJellycontainer}>
          <img
            className={styles.image}
            alt={title}
            src={carJellyImage}
            onError={event => {
              event.currentTarget.src = 'images/blank_car_PE.png';
            }}
            onLoad={vehicleSelectPageImageOnload}
          />
        </div>
      </div>

      {/* Payment details */}
      <div className={styles.col2}>
        <div className={styles.row}>
          {/* Months */}
          <Cell value={terms} unit={t('months')} columnSize="2" />

          {/* APR/ Mileage */}
          <Cell
            value={
              isBuyTab
                ? `${formatNumber((rate ?? 0) + (markup ?? 0))}%`
                : formatNumber(mileage)
            }
            unit={secondPaymentCell}
            columnSize="3"
          >
            {isBuyTab && <Disclaimer text={t('estimated_apr_tooltip')} />}
          </Cell>

          {/* Down Payment/ Due At Signing */}
          <Cell
            value={
              formatedThirdPaymentValue !== '' ? formatedThirdPaymentValue : '-'
            }
            unit={thirdPaymentCell}
            columnSize="3-5"
          >
            <Disclaimer text={thirdPaymentCellDisclaimer} />
          </Cell>

          {/* Lease/Finance cost pm  */}
          <Cell
            value={formatedPayment}
            supValue={fourthPaymentCell}
            subValue="/ month"
            animated={!isNaN(Number(formatedPayment))}
            addCurrency
            columnSize="3-5"
          >
            <Disclaimer width="78%" text={t('payment-disclaimer')} />
          </Cell>
        </div>
      </div>
    </div>
  );
};

export default observer(PaymentDetailsDesktop);
