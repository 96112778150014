import {
  EFCColorsSeriesList,
  EFCColorsV2Response,
} from '../models/EFC/EFCColorsV2';
import { getEFCColorsV2 } from './WebServicesAPI';

export type ColorObject = {
  code: string;
  title: string;
  car_jelly_image: string;
};

export type ColorData = {
  [year: string]: {
    [series: string]: {
      [trim: string]: {
        [exteriorCode: string]: ColorObject;
      };
    };
  };
};
export type ColorDataWithRegion = {
  [region: string]: ColorData;
};

const colorsData: ColorDataWithRegion = {};

const makeApiCall = async ({
  region,
  years,
}: {
  region: string;
  years: string;
}) => {
  const attributes = 'exterior.applicable';

  const result = await getEFCColorsV2({
    years,
    region,
    attributes,
  });

  return result;
};

export const getColors = async ({
  region,
  years,
}: {
  region: string;
  years: string;
}) => {
  // first check we don't have the data already...
  const yearsToSearch: string[] = years
    .split(',')
    .filter(year => !colorsData?.[region]?.[year]);

  if (yearsToSearch.length) {
    const yearsToQuery = yearsToSearch.join(',');

    try {
      const result = await makeApiCall({
        years: yearsToQuery,
        region,
      });

      mergeYearsResultIntoColorsData(region, result.data);
    } catch (e) {
      console.log(`getColorsErrors: ${e}`);
    }
  }

  return colorsData[region];
};

const mergeYearsResultIntoColorsData = (
  region: string,
  data: EFCColorsV2Response
) => {
  // initialise data for region if none
  if (!colorsData[region]) {
    colorsData[region] = {};
  }
  for (const yearE of Object.values(data?.years_list ?? {})) {
    // initialise year if not present
    if (!colorsData[region]?.[yearE.year]) {
      colorsData[region][yearE.year] = {};
    }

    for (const seriesE of yearE.series_list) {
      // initialise series if not present
      if (!colorsData[region][yearE.year]?.[seriesE.series]) {
        colorsData[region][yearE.year][seriesE.series] = {};
      }

      mergeExteriorsIntoTrims(region, yearE.year, seriesE);
    }
  }
};

// split for SonarQube
const mergeExteriorsIntoTrims = (
  region: string,
  year: string,
  seriesE: EFCColorsSeriesList
) => {
  for (const exterior of Object.values(seriesE?.colors?.exterior ?? {})) {
    for (const trim of Object.values(exterior.applicable ?? {})) {
      // initialise trim if not present
      if (!colorsData[region][year][seriesE.series]?.[trim.trim]) {
        colorsData[region][year][seriesE.series][trim.trim] = {};
      }

      // sometimes the trim object does not contain an override object,
      // do not add if not present
      if (trim.override && trim.override.images) {
        colorsData[region][year][seriesE.series][trim.trim][exterior.code] = {
          code: exterior.code,
          title: exterior.title,
          car_jelly_image: trim.override.images.car_jelly_image,
        };
      }
    }
  }
};
