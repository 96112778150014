import React, { useEffect } from 'react';
import cx from 'classnames';

import { observer } from 'mobx-react-lite';

import { useTranslation } from 'react-i18next';
import { useStore } from '../../../../stores';
import { useScrollTo } from '../../../../hooks/useScrollTo';

import styles from './DisclaimerModal.module.scss';
import { Disclosures } from '../../../../models/VIEW/ViewOffersTab';
import { msrpDisclosure } from 'constants/Disclosures';

const getDisclaimerDescription = (
  disclosures: Disclosures,
  stringID: string
) => {
  return (
    (disclosures.filter(item => item.disclosureType === stringID)[0] || {})
      .disclosureValue || 'Disclaimer Not Available at this time.'
  );
};

const createBuyDisclosures = (disclosures: Disclosures) => [
  {
    id: '1',
    description: getDisclaimerDescription(
      disclosures,
      'creditRatingDisclosure'
    ),
  },
  {
    id: '2',
    description: getDisclaimerDescription(disclosures, 'cashDownDisclosure'),
  },
  {
    id: '3',
    description: getDisclaimerDescription(disclosures, 'termDisclosure'),
  },
  {
    id: '4',
    description: getDisclaimerDescription(disclosures, 'aprInputDisclosure'),
  },
  {
    id: '5',
    description: getDisclaimerDescription(
      disclosures,
      'estimatedTradeInValueDisclosure'
    ),
  },
  {
    id: '7',
    description: getDisclaimerDescription(disclosures, 'msrpDisclosure'),
  },
];

const createLeaseDisclosures = (disclosures: Disclosures) => [
  {
    id: '1',
    description: getDisclaimerDescription(
      disclosures,
      'creditRatingDisclosure'
    ),
  },
  {
    id: '3',
    description: getDisclaimerDescription(disclosures, 'termDisclosure'),
  },
  {
    id: '5',
    description: getDisclaimerDescription(
      disclosures,
      'estimatedTradeInValueDisclosure'
    ),
  },
  {
    id: '6',
    description: getDisclaimerDescription(disclosures, 'mileageDisclosure'),
  },
  {
    id: '7',
    description: getDisclaimerDescription(disclosures, 'msrpDisclosure'),
  },
  {
    id: '8',
    description: getDisclaimerDescription(
      disclosures,
      'cashOutOfPocketDisclosure'
    ),
  },
];

const DisclaimerModal: React.FC = () => {
  const { t } = useTranslation();
  const modalBodyRef = React.useRef(null);
  const {
    modalStore: {
      displayDisclaimerModal,
      closeDisclaimerModal,
      disclosuresForModal,
      disclaimerId,
    },
    estimatorStore: { selectedTab },
  } = useStore();
  useScrollTo(modalBodyRef, `disclaimerModalElement-${disclaimerId}`);

  const disclosuresToDisplay = () => {
    if (window.location.href.includes('vehicleSelect')) {
      return [{ id: '1', description: msrpDisclosure }];
    } else {
      return selectedTab === 'buy'
        ? createBuyDisclosures(disclosuresForModal)
        : createLeaseDisclosures(disclosuresForModal);
    }
  };
  useEffect(() => {
    if (displayDisclaimerModal) {
      try {
        const dialog = document.getElementsByClassName(
          'modal-content-disclaimer'
        )[0] as HTMLElement;

        dialog.focus();
      } catch (e) {
        console.log(e);
      }
    }
  }, [displayDisclaimerModal]);

  return (
    <>
      <div
        className={cx(styles.modal, { [styles.open]: displayDisclaimerModal })}
      >
        <div
          className={cx(styles.modalContent, 'modal-content-disclaimer')}
          tabIndex={0}
        >
          <div className={styles.modalHeader}>
            <div className={styles.closeModal}>
              <button
                className={styles.closeButton}
                onClick={closeDisclaimerModal}
              >
                <span className={styles.closeSpan} aria-hidden />
              </button>
            </div>
            <h1 className={styles.title}>{t('disclaimers')}</h1>
          </div>
          <div className={styles.modalBody}>
            <section className={styles.section} ref={modalBodyRef}>
              <ul className={styles.list}>
                {disclosuresToDisplay().map(item => (
                  <li
                    className={styles.listElement}
                    key={item.id}
                    data-key={item.id}
                    id={`disclaimerModalElement-${item.id}`}
                  >
                    <aside className={styles.aside}>{item.id}</aside>
                    <article className={styles.article}>
                      {item.description}
                    </article>
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </div>
      </div>
      {displayDisclaimerModal && <div className={styles.overlay} />}
    </>
  );
};

export default observer(DisclaimerModal);
