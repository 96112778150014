import React from 'react';
import cx from 'classnames';
import ReactDOM from 'react-dom';

import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores';

import styles from './Modal.module.scss';
import { isIframed } from '../../utils/isIframed';

type ModalProps = {
  children: React.ReactNode;
  disabled?: boolean;
};

const Modal = (props: ModalProps) => {
  const inIframe = isIframed();

  const { children } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const {
    modalStore: { displayContactDealerModal, closeContactDealerModal },
  } = useStore();

  React.useEffect(() => {
    if (displayContactDealerModal) {
      document.body.style.overflowY = 'hidden';
      setTimeout(() => {
        setIsOpen(true);
      });
    } else {
      document.body.style.overflowY = '';
    }

    return () => {
      document.body.style.overflowY = '';
      setIsOpen(false);
    };
  }, [displayContactDealerModal, setIsOpen]);

  const ModalContent = (
    <section className={styles.modal} style={{ opacity: isOpen ? 1 : 0 }}>
      <div className={styles.dialog}>
        <div
          className={styles.backdrop}
          onClick={closeContactDealerModal}
          role="button"
        />
        <div
          className={cx(
            styles.content,
            'modal-content-contact',
            inIframe && styles.iframe
          )}
        >
          {children}
        </div>
      </div>
    </section>
  );

  return displayContactDealerModal
    ? ReactDOM.createPortal(ModalContent, document.body)
    : null;
};

export default observer(Modal);
