import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import handlerStore from '../../../../../stores/EstimatorPage/estimatorPageHandlers';
import {
  OfferForView,
  OfferType,
} from '../../../../../stores/EstimatorPage/Factories/offerFactory';

import { useStore } from '../../../../../stores';
import { formatNumberWithCurrency } from '../../../../../utils/numbers';

import styles from './Offer.module.scss';
import InfoIconPopup from '../../../../../components/InfoIconPopup';

type OfferProps = {
  offer: OfferForView;
};
const Offer: React.FC<OfferProps> = observer(({ offer }) => {
  const { t } = useTranslation();
  const {
    modalStore: { openOfferDetailsModal },
  } = useStore();
  const tooltipDescription = `${formatNumberWithCurrency(
    offer.dueAtSigning || 0
  )} ${t('due_at_signing')}`;
  const _onChange = () => !offer.disabled && handlerStore.offerClick(offer);

  const checkboxHandler = (event: { keyCode: number }) => {
    if (!offer.disabled && (event.keyCode === 32 || event.keyCode === 13)) {
      handlerStore.offerClick(offer);
    }
  };

  const offerDetailsHandler = (event: { keyCode: number }) => {
    if (event.keyCode === 32 || event.keyCode === 13) {
      openOfferDetailsModal(offer);
    }
  };

  return (
    <div className={styles.offerRoot}>
      <div className={styles.offerRow}>
        <div className={styles.checkboxWrapper}>
          <label className={styles.label} tabIndex={0}>
            <input
              type="checkbox"
              checked={offer.selected}
              className={styles.input}
              aria-checked={offer.selected}
              readOnly
            />
            <span
              className={cx(styles.checkMark, {
                [styles.active]: offer.selected,
                [styles.disabled]: offer.disabled,
              })}
              onClick={_onChange}
              onKeyDown={checkboxHandler}
              role="button"
              tabIndex={0}
            />
            <span className={styles.checkBoxLabel}>
              <div className={styles.labelText}>
                {offer.offerLabel}
                {offer.offerType === OfferType.LEASE && (
                  <InfoIconPopup
                    content={tooltipDescription}
                    iconClassName={styles.iconClassName}
                  />
                )}
              </div>
              <span
                tabIndex={0}
                className={styles.button}
                onClick={event => {
                  event.preventDefault();
                  openOfferDetailsModal(offer);
                }}
                onKeyDown={offerDetailsHandler}
                aria-haspopup={true} // tslint:disable-line
                role="button"
                aria-label={`offer-details-popup-${offer.id}`}
              >
                {t('see_offer_details')}
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
});

export default Offer;
