import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Dealer } from '../../../../../../models/VIEW/RequestAQuote';

import styles from './dealerForm.module.scss';

type Props = {
  loadMore: any;
  handleDealerZipChange: any;
  setSelectedDealer: any;
  handleEditZipCodeOpen: any;
  currentZip: string;
  limit: number;
  dealers: Dealer[];
  selectedDealer?: Dealer;
  zipRef: React.RefObject<HTMLInputElement>;
  editZipCode: boolean;
};

const DealerForm: React.FC<Props> = ({
  editZipCode,
  currentZip,
  zipRef,
  handleDealerZipChange,
  handleEditZipCodeOpen,
  dealers,
  setSelectedDealer,
  loadMore,
  limit,
  selectedDealer,
}) => {
  const { t } = useTranslation();
  const [zipCode, setZipCode] = useState(currentZip);

  useEffect(() => {
    handleDealerZipChange(zipCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode]);

  return (
    <>
      <div className={styles.dealerWrapper}>
        <label htmlFor="dealer" className={styles.label}>
          {t('contact_dealer_modal.select_a_dealer')}
          <span className={styles.editZipWrapper} tabIndex={0}>
            {editZipCode ? (
              <input
                maxLength={5}
                name="zipcode"
                className={cx({
                  [styles.zipcode]: true,
                  [styles.open]: editZipCode,
                })}
                placeholder="Zip Code"
                ref={zipRef}
                onChange={e => {
                  setZipCode(e.currentTarget.value.replace(/[^\d]/gm, ''));
                }}
                value={zipCode}
              />
            ) : (
              <span className={styles.zipcode}>{currentZip}</span>
            )}
            <span
              onClick={handleEditZipCodeOpen}
              className={styles.edit}
              role="button"
              aria-label={t('contact_dealer_modal.change_zip')}
              tabIndex={0}
            >
              &#x270e;
            </span>
          </span>
        </label>

        <div className={styles.dealerList}>
          {dealers.slice(0, limit).map((dealer: Dealer, index) => {
            return (
              <div key={index} className={styles.dealerDetailsContainer}>
                <input
                  type="radio"
                  className={cx(styles.radio)}
                  defaultChecked={dealer === selectedDealer}
                  name="dealers"
                  id={index.toString()}
                  value={dealer.code}
                  aria-checked={dealer === selectedDealer}
                  onChange={() => setSelectedDealer(dealer)}
                />
                <label
                  className={styles.dealerDetails}
                  tabIndex={0}
                  htmlFor={index.toString()}
                >
                  <span className={styles.dealerName}>{dealer.name}</span>
                  <span className={styles.distance}>
                    ({dealer.distance} {t('contact_dealer_modal.miles_away')})
                  </span>
                  <span className={styles.address}>
                    {dealer.contact.address}
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.viewMore}>
        <span onClick={loadMore} role="button" tabIndex={0}>
          {t('contact_dealer_modal.viewMore')}
        </span>
      </div>
    </>
  );
};

export default DealerForm;
