import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import ModalClose from '../../../../../../components/Modal/ModalClose';
import Button from '../../../../../../components/Button';
import { Dealer } from '../../../../../../models/VIEW/RequestAQuote';
import { useStore } from '../../../../../../stores';
import { OfferForView } from '../../../../../../stores/EstimatorPage/Factories/offerFactory';

import * as contactDealerAnalytics from '../../../../../../utils/Analytics/contactDealerAnalytics';

import styles from './Success.module.scss';

type SuccessProps = {
  dealer: Dealer;
  offers?: OfferForView[];
};

const Success: React.FC<SuccessProps> = ({ offers, dealer }) => {
  const { t } = useTranslation();
  const {
    modalStore: { closeContactDealerModal },
  } = useStore();

  const selectedOffers = offers?.filter(offer => offer.selected);

  return (
    <>
      <ModalClose />
      <section className={styles.success}>
        <h3>{t('succes_modal.success_title')}</h3>
        <p>
          {t('succes_modal.success_head')} {dealer.name}
        </p>
        <p>{t('succes_modal.success_descr')}</p>
        <p>{t('succes_modal.success_descr2')}</p>
        <Button
          variant="redBackground"
          onClick={() => {
            contactDealerAnalytics.successModalOnClose(dealer, selectedOffers);
            closeContactDealerModal();
          }}
        >
          {t('succes_modal.success_btn')}
        </Button>
      </section>
    </>
  );
};

export default observer(Success);
