import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import PaymentDetails from './components/PaymentDetails';
import FinanceTerms from '../FinanceTerms';
import Disclaimer from '../Disclaimer';
import Button from '../../../../modules/ZipCode/components/Button';

import styles from './BottomSectionMobile.module.scss';
import { useStore } from '../../../../stores';
import { Tab } from '../../../../models/VIEW/ViewOffersTab';

type Props = {
  title: string;
  payment: number;
  selectedTab: Tab;
};

const BottomSectionMobile: React.FC<Props> = ({
  title,
  payment,
  selectedTab,
}) => {
  const { t } = useTranslation();
  const {
    modalStore: { openContactDealerModal },
  } = useStore();

  return (
    <div className={styles.bottomSection}>
      <div className={styles.bottomSectionContainer}>
        <div className={styles.details}>
          <div className={styles.yearSeries}>
            <span className={styles.detailsSeries}>{title}</span>
          </div>
          <PaymentDetails />
          <Disclaimer
            className={styles.disclaimer}
            text={t('payment-disclaimer')}
          />
          <div className={styles.buttonWrapper}>
            <Button
              onClick={() => openContactDealerModal()}
              className={styles.btn}
              aria-haspopup="true"
            >
              {t('contact_dealer')}
            </Button>
          </div>
          <FinanceTerms
            className={styles.financeTerms}
            selectedTab={selectedTab}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(BottomSectionMobile);
