import React, { useState } from 'react';
import i18n from 'i18next';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../../stores';

import { buildContactDealerDisclaimerLink, createStaticComment } from './utils';

import Success from './Components/Success';

import { Dealer } from '../../../../models/VIEW/RequestAQuote';
import { getCreditScoreConstant } from '../../../../constants/CreditScoreConstants';
import ContactDealerInner from './ContactDealerInner';
import { useContactDealerHandlers } from './useContactDealerHandlers';

const ContactDealerModal: React.FC = () => {
  const { t } = useTranslation();
  const {
    estimatorStore: { payOptForm, selectedYearSeries, selectedTab },
    zipCodeStore: { inputZipCode, regionCode },
  } = useStore();

  const zipRef = React.useRef<HTMLInputElement>(null);
  const staticCommentTextArea = React.useRef<HTMLTextAreaElement>(null);

  const [editZipCode, setEditZipCode] = useState(false);
  const [currentZip, setCurrentZip] = useState(inputZipCode);
  const [selectedDealer, setSelectedDealer] = useState<Dealer>();
  const [firstInteraction, setFirstIntefaction] = useState(false);
  const [limit, setLimit] = useState(2);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dealers, setDealers] = useState<Dealer[]>([]);

  const { title } = selectedYearSeries;
  const {
    creditScore,
    downPayment,
    dueAtSigning,
    markup,
    mileage,
    payment,
    rate,
    selectedGradeTitle,
    selectedOffers,
    terms,
    tradeIn,
    baseMsrp,
  } = payOptForm;

  const creditScoreId = creditScore.id;
  const creditScoreLabel = `${t(
    getCreditScoreConstant(regionCode, selectedTab)[creditScoreId].label ?? ''
  )} (${getCreditScoreConstant(regionCode, selectedTab)[creditScoreId].score})`;

  const disclaimerLink = buildContactDealerDisclaimerLink(i18n.language);

  const staticComment = createStaticComment({
    baseMsrp,
    creditScoreLabel,
    downPayment,
    dueAtSigning,
    inputZipCode,
    markup,
    mileage,
    payment,
    rate,
    selectedOffers,
    selectedTab,
    terms,
    tradeIn,
  });

  const {
    handleDealerZipChange,
    handleEditZipCodeOpen,
    loadMore,
    handleInteraction,
    onSubmit,
    formik,
    onClose,
  } = useContactDealerHandlers({
    setCurrentZip,
    setEditZipCode,
    setSelectedDealer,
    setDealers,
    zipRef,
    limit,
    setLimit,
    currentZip,
    staticCommentTextArea,
    firstInteraction,
    setFirstIntefaction,
    selectedDealer,
    staticComment,
    setIsSubmitted,
  });

  return isSubmitted ? (
    <Success offers={selectedOffers} dealer={selectedDealer as Dealer} />
  ) : (
    <ContactDealerInner
      formik={formik}
      onSubmit={onSubmit}
      title={title}
      gradeTitle={selectedGradeTitle}
      handleInteraction={handleInteraction}
      currentZip={currentZip}
      staticCommentTextArea={staticCommentTextArea}
      staticComment={staticComment}
      loadMore={loadMore}
      limit={limit}
      dealers={dealers}
      selectedDealer={selectedDealer}
      setSelectedDealer={setSelectedDealer}
      editZipCode={editZipCode}
      zipRef={zipRef}
      handleEditZipCodeOpen={handleEditZipCodeOpen}
      handleDealerZipChange={handleDealerZipChange}
      selectedOffers={selectedOffers}
      disclaimerLink={disclaimerLink}
      onClose={onClose}
    />
  );
};

export default observer(ContactDealerModal);
