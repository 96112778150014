import { getDealersContactForm, getPEQuote } from './WebServicesAPI';
import { RequestAQuoteRequest, slpData } from '../models/VIEW/RequestAQuote';
import { CDASelectedTrim } from '../models/VIEW/ContactDealerFormSelectedTrim';
import { getCampaignCode } from '../utils/Analytics/campaignCode';
import cookie from 'js-cookie';
import { createRandomIdentifier } from '../utils/Analytics';

export const submitContactDealerForm = (
  formData: RequestAQuoteRequest,
  selectedTrim: CDASelectedTrim
) => {
  const data = createJSONFromForm(formData, selectedTrim);
  return getPEQuote(data);
};

export const getDealersForContactForm = async (region: string) => {
  const [pmaResp, proximityResp] = await getDealersContactForm(region);
  return disDealerXForm(pmaResp.data, proximityResp.data);
};

// helper for disDealerXForm
const initialiseDealers = (pma: any) => {
  const allDealers = [];
  for (const item of pma) {
    // Create simpler objects for display purposes.
    const distance = item.proximityMeasureGroup.proximityMeasure.value;

    // Service should only be returning dealers < 50m
    if (distance > 50) {
      continue;
    }
    const obj = {
      name: item.dealerParty.specifiedOrganization.companyName.value,
      distance: item.proximityMeasureGroup.proximityMeasure.value
        ? item.proximityMeasureGroup.proximityMeasure.value.toFixed(2)
        : '',
      isPma: item.isPma,
      code: item.dealerParty.partyID.value,
      isSelected: false,
      contact: {
        firstName: '',
        lastName: '',
        region: item.dealerParty.specifiedOrganization.divisionCode.value,
        street: '',
        city: '',
        zip: '',
        state: '',
        phone: '',
        email: '',
        website: '',
        address: '',
      },
    };

    // find main dealer contact
    const foundMainDealer = item.dealerParty.specifiedOrganization.primaryContact.filter(
      (_item: any) => _item.departmentName.value === 'Main Dealer'
    );

    if (foundMainDealer.length) {
      obj.contact.street = foundMainDealer[0].postalAddress.lineOne.value;
      obj.contact.city = foundMainDealer[0].postalAddress.cityName.value;
      obj.contact.zip = foundMainDealer[0].postalAddress.postcode.value;
      obj.contact.state =
        foundMainDealer[0].postalAddress.stateOrProvinceCountrySubDivisionID.value;
      obj.contact.address = `${obj.contact.street} ${obj.contact.city}, ${obj.contact.state} ${obj.contact.zip}`;

      // find phone
      const foundPhone = foundMainDealer[0].telephoneCommunication.filter(
        (_item: any) => _item.channelCode.value === 'Phone'
      );
      if (foundPhone.length) {
        obj.contact.phone = foundPhone[0].completeNumber.value;
      }

      // find website and email
      foundMainDealer[0].uricommunication.forEach((_item: any) => {
        switch (_item.channelCode.value) {
          case 'Email': {
            obj.contact.email = _item.uriid.value;
            break;
          }
          case 'Website': {
            obj.contact.website = _item.uriid.value;
            break;
          }
          default: {
            break;
          }
        }
      });
    }

    allDealers.push(obj);
  }
  return allDealers;
};

export const disDealerXForm = (pmaData: any, proximityData: any) => {
  const pma =
    pmaData.showDealerLocatorDataArea.dealerLocator[0].dealerLocatorDetail;
  const proximity =
    proximityData.showDealerLocatorDataArea.dealerLocator[0]
      .dealerLocatorDetail;

  // assign pma flag
  for (const ele of pma) {
    ele.isPma = true;
  }
  // push proximity items into pma
  for (const ele of proximity) {
    if (!pma) {
      continue;
    }
    // if item already exists in pma array, don't add it
    const foundItem = pma.filter(
      (o: any) => o.dealerParty.partyID.value === ele.dealerParty.partyID.value
    );
    if (!foundItem.length) {
      pma.push(ele);
    }
  }

  return initialiseDealers(pma);
};

const createJSONFromForm = (
  request: RequestAQuoteRequest,
  vehicle: CDASelectedTrim
) => {
  const {
    firstname,
    lastname,
    dealer,
    preference,
    email,
    phone,
    isMobile,
    inputComment,
    staticComment,
  } = request;

  const campaignCode = getCampaignCode(isMobile);
  const format = isMobile ? 'Mobile' : 'Website';
  const omnitureID = createRandomIdentifier();
  const requestDate = new Date();
  const comment = `${staticComment}${inputComment}`;
  const zipCodeAccuracy = cookie.get('zipcode_accuracy');

  let json: slpData = {
    adf: {
      prospect: {
        id: {
          value: '-2',
          sequence: '1',
          source: 'Toyota',
        },
        requestdate: requestDate,
        vehicle: {
          interest: vehicle.purchaseType,
          status: 'new',
          year: vehicle.year,
          make: 'Toyota',
          model: vehicle.series,
          trim: vehicle.trim,
        },
        customer: {
          contact: {
            name: [
              {
                value: firstname,
                part: 'first',
                type: 'individual',
              },
              {
                value: lastname,
                part: 'last',
                type: 'individual',
              },
            ],
          },
        },
        vendor: {
          id: {
            value: dealer.code,
            sequence: '1',
            source: 'TMS Dealer Code',
          },
          vendorname: dealer.name,
          url: dealer.contact.website,
          name: [
            {
              value: dealer.contact.firstName,
              part: 'first',
              type: 'individual',
            },
            {
              value: dealer.contact.lastName,
              part: 'last',
              type: 'individual',
            },
          ],
          email: {
            value: dealer.contact.email,
            preferredcontact: '0',
          },
          phone: {
            value: dealer.contact.phone,
            preferredcontact: '1',
            type: 'voice',
            time: 'nopreference',
          },
          address: {
            street: {
              line: '1',
              value: dealer.contact.street,
            },
            city: dealer.contact.city,
            regioncode: dealer.contact.region,
            postalcode: dealer.contact.zip,
          },
        },
        provider: {
          id: [
            {
              sequence: '1',
              source: 'Toyota',
            },
            {
              value: omnitureID,
              source: 'Omniture_ID',
            },
          ],
          name: format,
          service: campaignCode,
        },
      },
    },
  };

  if (preference === 'email') {
    json.adf.prospect.customer.contact.email = {
      value: email,
      preferredcontact: '0',
    };
  } else if (preference === 'phone') {
    json.adf.prospect.customer.contact.phone = {
      value: phone,
      preferredcontact: '1',
    };
  }

  if (comment) {
    json.adf.prospect.customer.comments = comment;
  }

  if (zipCodeAccuracy) {
    json.adf.prospect.provider.id.push({
      value: zipCodeAccuracy,
      source: 'Zipcode_Accuracy',
    });
  }

  return json;
};
